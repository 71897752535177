import React from "react";
import "./App.css";
import { HashRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import ContactPage from "./pages/Contact/ContactPage";
import DIPage from "./pages/DivadloDI/DIPage";
import RepertoirePage from "./pages/RepertoirePage/RepertoirePage";
import RuzenkaDetailPage from "./pages/DetailPages/RuzenkaDetailPage";
import HonzaDetailPage from "./pages/DetailPages/HonzaDetailPage";
import PlavacekDetailPage from "./pages/DetailPages/PlavacekDetailPage";
import NaPavlaciDetailPage from "./pages/DetailPages/NaPavlaciDetailPage";
import VanocniZemekouleDetailPage from "./pages/DetailPages/VanocniZemekouleDetailPage";
import SpoluDetailPage from "./pages/DetailPages/SpoluDetailPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import EventsPage from "./pages/Events/EventsPage";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminControls from "./pages/AdminControls/AdminControls";
import PotrestanaPychaDetailPage from "./pages/DetailPages/PotrestanaPychaDetailPage";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <Router>
    <HelmetProvider>
      <div className='App'>
        <Routes>
          <Route exact path='/' element={<HomePage/>} />
          <Route exact path='kontakty' element={<ContactPage />} />
          <Route path='divadloDi' element={<DIPage />} />
          <Route path='repertoar' element={<RepertoirePage />} />
          <Route path='ruzenka' element={<RuzenkaDetailPage />} />
          <Route path='honza' element={<HonzaDetailPage />} />
          <Route path='plavacek' element={<PlavacekDetailPage/>} />
          <Route path='naPavlaci' element={<NaPavlaciDetailPage/>} />
          <Route path='spolu' element={<SpoluDetailPage/>} />
          <Route path='vanocniZemekoule' element={<VanocniZemekouleDetailPage/>} />
          <Route path='potrestanaPycha' element={<PotrestanaPychaDetailPage/>} />
          <Route exact path='program' element={<EventsPage/>} />
          <Route exact path='adminlogin' element={<AdminLogin/>} />
          <Route exact path='admincontrols' element={<AdminControls/>} />
          <Route path='*' component={<NotFoundPage/>} />
        </Routes>
      </div>
      <Analytics/>
      <SpeedInsights/>
      </HelmetProvider>
    </Router>
  );
};

export default App;
