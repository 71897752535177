import React from 'react';
import Navbar from '../../components/NavBar/Navbar';
import SlideShow from '../../components/SlideShow/SlideShow';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';

const Homepage = ({ images }) => {
  return (
    <>
      <Helmet>
        <title>Úvodní stránka | Divadlo Di</title>
        <meta name="description" content="Pojď prozkoumat stránky Divadla Di. Nalezneš zde všechny naše vystoupení, fotky, termíny a mnohem více." />
        <meta name="keywords" content="home page, landing page, úvodní stránka, divadlo, divadlo DI, DI, divadelní hry" />
        <link rel="canonical" href="https://www.divadlodi.cz/" />
      </Helmet>
      <Navbar/>
      <SlideShow/>
      <Footer/>
    </>
  );
};

export default Homepage;