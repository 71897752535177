import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "./DetailPages.css";
import Title from "../../assets/naPavlaci/title.webp";
import Picture1 from "../../assets/naPavlaci/1.webp";
import Picture2 from "../../assets/naPavlaci/2.webp";
import Picture3 from "../../assets/naPavlaci/3.webp";
import Picture4 from "../../assets/naPavlaci/4.webp";
import Picture5 from "../../assets/naPavlaci/5.webp";
import Picture6 from "../../assets/naPavlaci/6.webp";
import Picture7 from "../../assets/naPavlaci/7.webp";
import Picture8 from "../../assets/naPavlaci/8.webp";
import Picture9 from "../../assets/naPavlaci/9.webp";
import Picture10 from "../../assets/naPavlaci/10.webp";
import Picture11 from "../../assets/naPavlaci/11.webp";
import Picture12 from "../../assets/naPavlaci/12.webp";
import Picture13 from "../../assets/naPavlaci/13.webp";
import Picture14 from "../../assets/naPavlaci/14.webp";
import Picture15 from "../../assets/naPavlaci/15.webp";
import Picture16 from "../../assets/naPavlaci/16.webp";
import Picture17 from "../../assets/naPavlaci/17.webp";
import Picture18 from "../../assets/naPavlaci/18.webp";
import Picture19 from "../../assets/naPavlaci/19.webp";
import Picture20 from "../../assets/naPavlaci/20.webp";
import Picture21 from "../../assets/naPavlaci/21.webp";
import Picture22 from "../../assets/naPavlaci/22.webp";
import Picture23 from "../../assets/naPavlaci/23.webp";
import Picture24 from "../../assets/naPavlaci/24.webp";
import Navbar from "../../components/NavBar/Navbar";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";
import ImageGalery from "../../components/ImageGalery/ImageGalery";
import Footer from "../../components/Footer/Footer";

const DetailPage = () => {
  const images = [
    { original: Picture1, thumbnail: Picture1 },
    { original: Picture2, thumbnail: Picture2 },
    { original: Picture3, thumbnail: Picture3 },
    { original: Picture4, thumbnail: Picture4 },
    { original: Picture5, thumbnail: Picture5 },
    { original: Picture6, thumbnail: Picture6 },
    { original: Picture7, thumbnail: Picture7 },
    { original: Picture8, thumbnail: Picture8 },
    { original: Picture9, thumbnail: Picture9 },
    { original: Picture10, thumbnail: Picture10 },
    { original: Picture11, thumbnail: Picture11 },
    { original: Picture12, thumbnail: Picture12 },
    { original: Picture13, thumbnail: Picture13 },
    { original: Picture14, thumbnail: Picture14 },
    { original: Picture15, thumbnail: Picture15 },
    { original: Picture16, thumbnail: Picture16 },
    { original: Picture17, thumbnail: Picture17 },
    { original: Picture18, thumbnail: Picture18 },
    { original: Picture19, thumbnail: Picture19 },
    { original: Picture20, thumbnail: Picture20 },
    { original: Picture21, thumbnail: Picture21 },
    { original: Picture22, thumbnail: Picture22 },
    { original: Picture23, thumbnail: Picture23 },
    { original: Picture24, thumbnail: Picture24 },
    
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className='detail-page'>
        <div className='header'>
          <img src={Title} alt='Header' className='header-image' />
          <h1 className='header-title'>Na Pavlači</h1>
        </div>
        <div  id="info" className='info-section'>
          <p>
            Nahlédněte pod pokličku dvou žen před padesátkou a zjistěte, co jim
            nejvíce leží na srdci - vrásky, špíčky, práce nebo muži? To vše a
            mnohem více můžete shlédnout v komedii Na pavlači provoněné kávou...
          </p>
          <div className='short-info'>
            <div className='info-item'>
              <strong>Hrají:</strong> Markéta Voříšková - Berta, Adéla Pellarová - Evža
            </div>
            <div className='info-item'>
              <strong>Scénografie a kostýmy:</strong> Monika Münsterová
            </div>
            <div className='info-item'>
              <strong>Hudba:</strong> Claud Debussy - The Little Negro, Ludwig
              van Beethoven - Sonata Opus 27, No. 2, 3. věta Presto agiato
            </div>
            <div className='info-item'>
              <strong>Klavíristka:</strong> Barbora Machovcová
            </div>
            <div className='info-item'>
              <strong>Technická spolupráce:</strong> Jiří Holomek
            </div>
            <div className='info-item'>
              <strong>Prostorová náročnost:</strong> Toto představení hrajeme v
              Divadeliéru i zájezdově.
            </div>
            <div className='info-item'>
              <strong>Délka trvání:</strong> 60 minut
            </div>
          </div>
        </div>
        <ImageGalery images={images} h2={"foto: Petra Šplíchalová"} />
      </div>
      <ScrollButton />
      <Footer/>
    </>
  );
};

export default DetailPage;
