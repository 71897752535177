import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import "./EventsPage.css";
import Navbar from "../../components/NavBar/Navbar";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";
import SlideShow from "../../components/SlideShow/SlideShow";
import nearest from "nearest-date";
import Footer from "../../components/Footer/Footer";

const EventsPage = () => {
  const [data, setData] = useState([]);
  const backendUrl = "https://divadelier-backend.vercel.app";

  useEffect(() => {
    async function grabData() {
      const response = await axios.get(backendUrl + "/events");
      if (response.status === 200) {
        setData(response.data);
      }
    }
    grabData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}. ${month} ${year}`;
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSortAscending = () => {
    const sorted = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    setData(sorted);
  };

  const handleSortDescending = () => {
    const sorted = [...data].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setData(sorted);
  };

  const filterEvents = (events) => {
    return events.filter(
      (e) =>
        e.info.toLowerCase().includes(searchTerm.toLowerCase()) ||
        e.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(e.date).includes(searchTerm)
    );
  };

  useEffect(()=>{
    nearestDate();
  })

  const nearestDate = () => {
    let allDates = [];
    let x;
    const target = new Date();

    data.map((event, index) => {
      x = new Date(event.date);
      allDates.push(x);
      return console.log("sorted");
    });

    let index = nearest(allDates, target);
    const element = document.getElementById(index);

    if (element) {
      element.style.backgroundColor="#d2d2d2";
    element.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
    }
  };

  return (
    <>
      <Helmet>
        <title>Plánované akce | Divadlo Di</title>
        <meta name='description' content='Chtěl by ses přijít podívat na naše vystoupení? Hrajeme u tebe? Tady najdeš všechny temíny.'/>
        <meta name='keywords' content='events, program, vystoupení, plánované akce, divadlo, divadlo DI, DI, divadelní hry'/>
        <link rel='canonical' href='https://www.divadlodi.cz/#/program' />
      </Helmet>
      <div className='events events-container'>
        <Navbar />
        <div className='slideshow'>
          <SlideShow />
        </div>
        <div className='event-container'>
          <h2>Plánované akce</h2>
          <div className='search-bar-container'>
            <button onClick={handleSortAscending}>Představení vzestupně</button>
            <div className='search-bar'>
              <input
                type='text'
                placeholder='Vyhledat...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className='fa fa-search'></i>
            </div>
            <button onClick={handleSortDescending}>Představení sestupně</button>
          </div>
          {data &&
            filterEvents(data).map((event, index) => (
              <div id={index} key={index} className='event-item'>
                <div className='event event-date'>
                  <i className='fa fa-calendar'></i> {formatDate(event.date)}
                </div>
                <div className='event event-place'>
                  <i className='fa fa-map-marker'></i> {event.location}
                </div>
                <div className='event event-name'>
                  <i className='fa fa-info-circle'></i> {event.info}
                </div>
              </div>
            ))}
        </div>
      </div>
      <ScrollButton />
      <Footer/>
    </>
  );
};

export default EventsPage;