import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./DetailPages.css";
import Title from "../../assets/potrestanaPycha/title.webp";
import Picture1 from "../../assets/potrestanaPycha/1.webp";
import Picture2 from "../../assets/potrestanaPycha/2.webp";
import Picture3 from "../../assets/potrestanaPycha/3.webp";
import Picture4 from "../../assets/potrestanaPycha/4.webp";
import Picture5 from "../../assets/potrestanaPycha/5.webp";
import Picture6 from "../../assets/potrestanaPycha/6.webp";
import Picture7 from "../../assets/potrestanaPycha/7.webp";
import Picture8 from "../../assets/potrestanaPycha/8.webp";
import Picture9 from "../../assets/potrestanaPycha/9.webp";
import Picture10 from "../../assets/potrestanaPycha/10.webp";
import Picture11 from "../../assets/potrestanaPycha/11.webp";
import Picture12 from "../../assets/potrestanaPycha/12.webp";
import Picture13 from "../../assets/potrestanaPycha/13.webp";
import Picture14 from "../../assets/potrestanaPycha/14.webp";
import Picture15 from "../../assets/potrestanaPycha/15.webp";
import Picture16 from "../../assets/potrestanaPycha/16.webp";
import Picture17 from "../../assets/potrestanaPycha/17.webp";
import Navbar from "../../components/NavBar/Navbar";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";
import ImageGalery from "../../components/ImageGalery/ImageGalery";
import Footer from "../../components/Footer/Footer";

const DetailPage = () => {
  const images = [
    { original: Picture1, thumbnail: Picture1 },
    { original: Picture2, thumbnail: Picture2 },
    { original: Picture3, thumbnail: Picture3 },
    { original: Picture4, thumbnail: Picture4 },
    { original: Picture5, thumbnail: Picture5 },
    { original: Picture6, thumbnail: Picture6 },
    { original: Picture7, thumbnail: Picture7 },
    { original: Picture8, thumbnail: Picture8 },
    { original: Picture9, thumbnail: Picture9 },
    { original: Picture10, thumbnail: Picture10 },
    { original: Picture11, thumbnail: Picture11 },
    { original: Picture12, thumbnail: Picture12 },
    { original: Picture13, thumbnail: Picture13 },
    { original: Picture14, thumbnail: Picture14 },
    { original: Picture15, thumbnail: Picture15 },
    { original: Picture16, thumbnail: Picture16 },
    { original: Picture17, thumbnail: Picture17 },
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className='detail-page'>
        <div className='header'>
          <img src={Title} alt='Header' className='header-image' />
          <h1 className='header-title'>Potrestaná pýcha</h1>
        </div>
        <div id='info' className='info-section'>
          <p>
            Činoherní veselá pohádka na motivy pohádky Boženy Němcové o pyšné
            princezně. Král Miroslav svou láskou princeznu Krasomilu vyseká z
            její pýchy. Humorná pohádka o poznávání síly lásky, která dokáže
            proměnit člověka v srdečnou osobu.
          </p>
          <div className='short-info'>
            <div className='info-item'>
              <strong>Hrají:</strong> Adam Horký, Adéla Pellarová
            </div>
            <div className='info-item'>
              <strong>Scénář a režie:</strong> Adéla Pellarová
            </div>
            <div className='info-item'>
              <strong>Scénografie a návrhy kostýmů:</strong> Adéla Pellarová
            </div>
            <div className='info-item'>
              <strong>Výroba kulis:</strong> Petr Kubík
            </div>
            <div className='info-item'>
              <strong>Výtvarné ztvárnění kulis:</strong> Anežka Plháková
            </div>
            <div className='info-item'>
              <strong>Výroba kostýmů:</strong> Míňa Podhájecká, Mirka Voříšková,
              Šárka Tomanová, Adéla Pellarová, fundus divadla Di
            </div>
            <div className='info-item'>
              <strong>Hudba:</strong> W.A.Mozart - Rondo in D major KV485,
              George Gershwin - Rhapsody in Blue
            </div>
            <div className='info-item'>
              <strong>Piano:</strong> Bára Machovcová
            </div>
            <div className='info-item'>
              <strong>Saxofon:</strong> Patrik Žalud
            </div>
            <div className='info-item'>
              <strong>Zvuk:</strong> Tomáš Outrata
            </div>
            <div className='info-item'>
              <strong>Prostorová náročnost:</strong> Představení lze hrát na
              jevišti i ve třídě.
            </div>
            <div className='info-item'>
              <strong>Délka trvání:</strong> 45 minut
            </div>
          </div>
        </div>
        <ImageGalery images={images} h2={"foto: Petra Šplíchalová"} />
      </div>
      <ScrollButton />
      <Footer/>
    </>
  );
};

export default DetailPage;
